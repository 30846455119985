var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.date)?_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"font font-weight-bold",attrs:{"text":"Record Sales For Shops"}})],1):_vm._e(),(_vm.date)?_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-menu',{staticClass:"ft font-weight-medium  ",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-left":10,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font mt-2 ml-2 font-weight-medium ",attrs:{"outlined":"","disabled":_vm.isListLoading,"min-width":"120px","prepend-icon":"done"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"ft"},[_vm._v(_vm._s(_vm.date ? _vm.date : "Change Sales Recording Date"))])])]}}],null,false,1313784180),model:{value:(_vm.menu.edit),callback:function ($$v) {_vm.$set(_vm.menu, "edit", $$v)},expression:"menu.edit"}},[_c('v-date-picker',{staticClass:"font font-weight-medium  ",attrs:{"max":_vm._f("yesterday")(_vm.today,'YYYY-MM-DD'),"event-color":"primary"},on:{"input":function($event){_vm.menu.edit = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 ml-2",attrs:{"loading":_vm.isListLoading,"icon":"","fab":"","small":"","color":"primary"},on:{"click":function($event){_vm.$store.dispatch('sales/recordedShopSales', {
              date: _vm.date
            }),
              _vm.$store.dispatch('prices/listAllocationAmount')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}],null,false,2979223327)},[_c('span',{staticClass:"  font font-weight-medium font-size-sm"},[_vm._v("Refresh Page")])])],1):_vm._e(),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.isListLoading)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('v-progress-circular',{attrs:{"width":"2","size":"40","indeterminate":"","color":"primary"}})],1):_vm._e(),(
          (_vm.endOfDaySales.salesAmount < _vm.totalAmountTallied &&
            !_vm.recordedShopSales) ||
            (_vm.endOfDaySales.mdsAllocationAmount <
              _vm.sum(
                _vm.endOfDaySales.cashPayments.filter(
                  function (sale) { return sale.isPaymentMadeFromSalesAmount === 'mds-allocation'; }
                ),
                'amount'
              ) +
                _vm.sum(
                  _vm.endOfDaySales.cashPurchases.filter(
                    function (sale) { return sale.isPaymentMadeFromSalesAmount === 'mds-allocation'; }
                  ),
                  'amount'
                ) &&
              !_vm.recordedShopSales)
        )?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(" Total amount of sales should be balanced ")])]):_vm._e(),(_vm.date && _vm.recordedShopSales)?_c('v-alert',{staticClass:"animate__animated animate__shakeX",attrs:{"dense":"","type":"success"}},[_c('span',{staticClass:"font font-weight-medium font-size-md"},[_vm._v(" Shops sales for "+_vm._s(_vm._f("timestamp")(_vm.date,"Do MMMM, YYYY"))+" has been recorded. ")])]):_vm._e(),(_vm.date)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 animate__animated animate__fadeIn",staticStyle:{"width":"100%"}},[_c('ValidationObserver',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmationAlert)}}},[[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-4"},[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:" font font-weight-medium font-size-md pa-1",attrs:{"outlined":"","prepend-icon":"shopping_basket","error-messages":errors[0],"hint":"Total end of sales amount","prefix":_vm.currency && _vm.currency.length > 0
                        ? _vm.currency[0].symbol
                        : 'GHS',"persistent-hint":"","label":"Total Sales Amount"},model:{value:(_vm.endOfDaySales.salesAmount),callback:function ($$v) {_vm.$set(_vm.endOfDaySales, "salesAmount", $$v)},expression:"endOfDaySales.salesAmount"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:" font font-weight-medium font-size-md pa-1",attrs:{"outlined":"","readonly":"","loading":_vm.subLoading,"prepend-icon":"credit_card","error-messages":errors[0],"hint":"MDs total amount added","append-icon":"add","prefix":_vm.currency && _vm.currency.length > 0
                        ? _vm.currency[0].symbol
                        : 'GHS',"persistent-hint":"","label":"MDs Allocation Amount"},on:{"click:append":function($event){return _vm.open('add')}},model:{value:(_vm.endOfDaySales.mdsAllocationAmount),callback:function ($$v) {_vm.$set(_vm.endOfDaySales, "mdsAllocationAmount", $$v)},expression:"endOfDaySales.mdsAllocationAmount"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:" font font-weight-medium font-size-md pa-1",attrs:{"outlined":"","disabled":"","error-messages":errors[0],"prefix":_vm.currency && _vm.currency.length > 0
                        ? _vm.currency[0].symbol
                        : 'GHS',"label":"Total Amount Left In Hand","hint":"Cash to bank after sales tally","persistent-hint":""},model:{value:(_vm.endOfDaySales.cashDeposit),callback:function ($$v) {_vm.$set(_vm.endOfDaySales, "cashDeposit", $$v)},expression:"endOfDaySales.cashDeposit"}})]}}],null,true)})],1),_c('RecordCashPurchases',{attrs:{"date":_vm.date,"hasMDsAllocationTallied":_vm.endOfDaySales.mdsAllocationAmount <=
                    _vm.balancingAmount(
                      _vm.endOfDaySales.cashPayments,
                      _vm.endOfDaySales.cashPurchases,
                      'mds-allocation'
                    ),"totalSalesAmount":isNaN(parseFloat(_vm.endOfDaySales.salesAmount))
                    ? 0
                    : parseFloat(_vm.endOfDaySales.salesAmount),"totalAmountTallied":_vm.totalAmountTallied,"cashPurchases":_vm.endOfDaySales.cashPurchases,"currency":_vm.currency,"suppliers":_vm.suppliers,"salesPaymentOptions":_vm.salesPaymentOptions},on:{"addCashPurchase":_vm.addCashPurchase}}),_c('RecordCashPayments',{attrs:{"date":_vm.date,"hasMDsAllocationTallied":_vm.endOfDaySales.mdsAllocationAmount <=
                    _vm.balancingAmount(
                      _vm.endOfDaySales.cashPayments,
                      _vm.endOfDaySales.cashPurchases,
                      'mds-allocation'
                    ),"totalSalesAmount":isNaN(parseFloat(_vm.endOfDaySales.salesAmount))
                    ? 0
                    : parseFloat(_vm.endOfDaySales.salesAmount),"totalAmountTallied":_vm.totalAmountTallied,"cashPayments":_vm.endOfDaySales.cashPayments,"currency":_vm.currency,"suppliers":_vm.suppliers,"salesPaymentOptions":_vm.salesPaymentOptions},on:{"addCashPayment":_vm.addCashPayment}}),_c('RecordCreditPurchases',{attrs:{"date":_vm.date,"creditPurchases":_vm.endOfDaySales.creditPurchases,"currency":_vm.currency,"suppliers":_vm.suppliers},on:{"addCreditPurchase":_vm.addCreditPurchase}})],_c('div',{staticClass:"d-flex flex-column flex-grow-1 mt-4"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.endOfDaySales.salesAmount < _vm.totalAmountTallied ||
                    _vm.endOfDaySales.mdsAllocationAmount <
                      _vm.balancingAmount(
                        _vm.endOfDaySales.cashPayments,
                        _vm.endOfDaySales.cashPurchases,
                        'mds-allocation'
                      ) ||
                    !!_vm.recordedShopSales ||
                    _vm.loading}},[_c('span',{staticClass:"font font-weight-semibold text-uppercase font-size-md"},[_vm._v(" Close Sales For "+_vm._s(_vm.date))])])],1)],2)]}}],null,false,3798657671)})],1):[_c('div',{staticClass:"d-flex flex-row flex-fill justify-center mt-16 animate__animated animate__fadeIn"},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require('@/assets/close.svg')}})]),_c('div',{staticClass:"d-flex flex-column flex-fill align-center mt-10"},[_c('span',{staticClass:"font font-weight-medium pt-2 animate__animated animate_fadeInDown"},[_vm._v(" Select A Date To Record Shop Sales ")]),_c('v-chip',{staticClass:"font font-weight-medium font-size-sm my-2",attrs:{"color":"primary","label":"","small":""}},[_vm._v("Instructions to Follow")]),_c('span',{staticClass:"font font-weight-medium font-size-sm pt-2"},[_vm._v("Choose "),_c('kbd',[_vm._v("a date")]),_vm._v(" to close the shop's overall daily sale")]),_c('p',[_c('v-menu',{staticClass:"font font-weight-medium  ",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-left":10,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font mt-4 font-weight-medium",attrs:{"outlined":"","disabled":_vm.isListLoading,"min-width":"120px"}},'v-btn',attrs,false),on),[_c('span',{staticClass:" font font-weight-medium"},[_vm._v(_vm._s(_vm.date ? _vm.date : "Choose A Date"))])])]}}]),model:{value:(_vm.menu.add),callback:function ($$v) {_vm.$set(_vm.menu, "add", $$v)},expression:"menu.add"}},[_c('v-date-picker',{staticClass:"font font-weight-medium  ",attrs:{"event-color":"primary","max":_vm._f("yesterday")(_vm.today,'YYYY-MM-DD')},on:{"input":function($event){_vm.menu.add = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),(_vm.isListLoading)?_c('span',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"30","width":"2"}})],1):_vm._e()],1)]],2)],1),_c('AddMDsAllocationAmount',{attrs:{"state":_vm.dialog('add'),"loading":_vm.loading,"currency":_vm.currency},on:{"close":_vm.close,"addAllocationAmount":_vm.addAllocationAmount}}),_c('ConfirmationDialog',{attrs:{"state":_vm.dialog('alert'),"loading":_vm.loading},on:{"save":_vm.recordDailySales,"close":_vm.close}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"status":_vm.status,"message":_vm.message}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }