<template>
  <v-container>
    <div v-if="date" class="ml-2">
      <PageTitle
        :text="`Record Sales For Shops`"
        class="font font-weight-bold"
      />
    </div>
    <div v-if="date" class="buttonText ml-1" id="result">
      <v-menu
        v-model="menu.edit"
        :close-on-content-click="false"
        transition="scale-transition"
        :nudge-left="10"
        offset-y
        min-width="auto"
        class="ft font-weight-medium  "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="font mt-2 ml-2 font-weight-medium "
            outlined
            v-bind="attrs"
            :disabled="isListLoading"
            v-on="on"
            min-width="120px"
            prepend-icon="done"
          >
            <span class="ft">{{
              date ? date : "Change Sales Recording Date"
            }}</span>
          </v-btn>
        </template>
        <v-date-picker
          :max="today | yesterday('YYYY-MM-DD')"
          class="font font-weight-medium  "
          v-model="date"
          event-color="primary"
          @input="menu.edit = false"
        ></v-date-picker>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :loading="isListLoading"
            icon
            fab
            @click="
              $store.dispatch('sales/recordedShopSales', {
                date: date
              }),
                $store.dispatch('prices/listAllocationAmount')
            "
            small
            v-on="on"
            color="primary"
            class="mt-2 ml-2"
          >
            <i class="material-icons-outlined">refresh</i>
          </v-btn>
        </template>
        <span class="  font font-weight-medium font-size-sm">Refresh Page</span>
      </v-tooltip>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <div
          v-if="isListLoading"
          class="d-flex flex-row flex-grow-1 justify-center"
        >
          <v-progress-circular
            width="2"
            size="40"
            indeterminate
            color="primary"
          />
        </div>
        <v-alert
          type="error"
          dense
          v-if="
            (endOfDaySales.salesAmount < totalAmountTallied &&
              !recordedShopSales) ||
              (endOfDaySales.mdsAllocationAmount <
                sum(
                  endOfDaySales.cashPayments.filter(
                    sale =>
                      sale.isPaymentMadeFromSalesAmount === 'mds-allocation'
                  ),
                  'amount'
                ) +
                  sum(
                    endOfDaySales.cashPurchases.filter(
                      sale =>
                        sale.isPaymentMadeFromSalesAmount === 'mds-allocation'
                    ),
                    'amount'
                  ) &&
                !recordedShopSales)
          "
        >
          <span class="font font-weight-medium font-size-sm">
            Total amount of sales should be balanced
          </span>
        </v-alert>
        <v-alert
          dense
          v-if="date && recordedShopSales"
          type="success"
          class="animate__animated animate__shakeX"
        >
          <span class="font font-weight-medium font-size-md">
            Shops sales for {{ date | timestamp("Do MMMM, YYYY") }} has been
            recorded.
          </span>
        </v-alert>
        <div
          v-if="date"
          class="d-flex flex-row flex-grow-1 animate__animated animate__fadeIn"
          style="width: 100%;"
        >
          <ValidationObserver
            tag="div"
            class="d-flex flex-row flex-grow-1"
            v-slot="{ handleSubmit }"
          >
            <form
              style="width: 100%;"
              @submit.prevent="handleSubmit(confirmationAlert)"
            >
              <template>
                <div class="d-flex flex-row flex-grow-1 mt-4">
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-column flex-grow-1"
                    rules="required|decimals"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      prepend-icon="shopping_basket"
                      v-model="endOfDaySales.salesAmount"
                      :error-messages="errors[0]"
                      hint="Total end of sales amount"
                      :prefix="
                        currency && currency.length > 0
                          ? currency[0].symbol
                          : 'GHS'
                      "
                      persistent-hint
                      label="Total Sales Amount"
                      class=" font font-weight-medium font-size-md pa-1"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-column flex-grow-1"
                    rules="required|decimals"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      readonly
                      :loading="subLoading"
                      prepend-icon="credit_card"
                      v-model="endOfDaySales.mdsAllocationAmount"
                      :error-messages="errors[0]"
                      hint="MDs total amount added"
                      append-icon="add"
                      @click:append="open('add')"
                      :prefix="
                        currency && currency.length > 0
                          ? currency[0].symbol
                          : 'GHS'
                      "
                      persistent-hint
                      label="MDs Allocation Amount"
                      class=" font font-weight-medium font-size-md pa-1"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-column flex-grow-1"
                    rules="required|decimals"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      disabled
                      v-model="endOfDaySales.cashDeposit"
                      :error-messages="errors[0]"
                      :prefix="
                        currency && currency.length > 0
                          ? currency[0].symbol
                          : 'GHS'
                      "
                      label="Total Amount Left In Hand"
                      hint="Cash to bank after sales tally"
                      persistent-hint
                      class=" font font-weight-medium font-size-md pa-1"
                    />
                  </ValidationProvider>
                </div>
                <RecordCashPurchases
                  :date="date"
                  @addCashPurchase="addCashPurchase"
                  :hasMDsAllocationTallied="
                    endOfDaySales.mdsAllocationAmount <=
                      balancingAmount(
                        endOfDaySales.cashPayments,
                        endOfDaySales.cashPurchases,
                        'mds-allocation'
                      )
                  "
                  :totalSalesAmount="
                    isNaN(parseFloat(endOfDaySales.salesAmount))
                      ? 0
                      : parseFloat(endOfDaySales.salesAmount)
                  "
                  :totalAmountTallied="totalAmountTallied"
                  :cashPurchases="endOfDaySales.cashPurchases"
                  :currency="currency"
                  :suppliers="suppliers"
                  :salesPaymentOptions="salesPaymentOptions"
                />
                <RecordCashPayments
                  :date="date"
                  :hasMDsAllocationTallied="
                    endOfDaySales.mdsAllocationAmount <=
                      balancingAmount(
                        endOfDaySales.cashPayments,
                        endOfDaySales.cashPurchases,
                        'mds-allocation'
                      )
                  "
                  :totalSalesAmount="
                    isNaN(parseFloat(endOfDaySales.salesAmount))
                      ? 0
                      : parseFloat(endOfDaySales.salesAmount)
                  "
                  :totalAmountTallied="totalAmountTallied"
                  @addCashPayment="addCashPayment"
                  :cashPayments="endOfDaySales.cashPayments"
                  :currency="currency"
                  :suppliers="suppliers"
                  :salesPaymentOptions="salesPaymentOptions"
                />
                <RecordCreditPurchases
                  :date="date"
                  :creditPurchases="endOfDaySales.creditPurchases"
                  :currency="currency"
                  :suppliers="suppliers"
                  @addCreditPurchase="addCreditPurchase"
                />
              </template>

              <div class="d-flex flex-column flex-grow-1 mt-4">
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  :disabled="
                    endOfDaySales.salesAmount < totalAmountTallied ||
                      endOfDaySales.mdsAllocationAmount <
                        balancingAmount(
                          endOfDaySales.cashPayments,
                          endOfDaySales.cashPurchases,
                          'mds-allocation'
                        ) ||
                      !!recordedShopSales ||
                      loading
                  "
                >
                  <span
                    class="font font-weight-semibold text-uppercase font-size-md"
                  >
                    Close Sales For {{ date }}</span
                  >
                </v-btn>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <template v-else>
          <div
            class="d-flex flex-row flex-fill justify-center mt-16 animate__animated animate__fadeIn"
          >
            <img :src="require('@/assets/close.svg')" style="width: 150px;" />
          </div>
          <div class="d-flex flex-column flex-fill align-center mt-10">
            <span
              class="font font-weight-medium pt-2 animate__animated animate_fadeInDown"
            >
              Select A Date To Record Shop Sales
            </span>

            <v-chip
              color="primary"
              label
              small
              class="font font-weight-medium font-size-sm my-2"
              >Instructions to Follow</v-chip
            >

            <span class="font font-weight-medium font-size-sm pt-2"
              >Choose <kbd>a date</kbd> to close the shop's overall daily
              sale</span
            >
            <p>
              <v-menu
                v-model="menu.add"
                :close-on-content-click="false"
                transition="scale-transition"
                :nudge-left="10"
                offset-y
                min-width="auto"
                class="font font-weight-medium  "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="font mt-4 font-weight-medium"
                    outlined
                    v-bind="attrs"
                    :disabled="isListLoading"
                    v-on="on"
                    min-width="120px"
                  >
                    <span class=" font font-weight-medium">{{
                      date ? date : "Choose A Date"
                    }}</span>
                  </v-btn>
                </template>
                <v-date-picker
                  event-color="primary"
                  :max="today | yesterday('YYYY-MM-DD')"
                  class="font font-weight-medium  "
                  v-model="date"
                  @input="menu.add = false"
                ></v-date-picker>
              </v-menu>
            </p>

            <span v-if="isListLoading">
              <v-progress-circular
                indeterminate
                color="primary"
                size="30"
                width="2"
              />
            </span>
          </div>
        </template>
      </v-col>
    </v-row>
    <AddMDsAllocationAmount
      :state="dialog('add')"
      @close="close"
      @addAllocationAmount="addAllocationAmount"
      :loading="loading"
      :currency="currency"
    />

    <ConfirmationDialog
      :state="dialog('alert')"
      :loading="loading"
      @save="recordDailySales"
      @close="close"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :status="status"
      :message="message"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import DialogMixins from "../../mixins/DialogMixins";
import { timestamp } from "../../filters/timestamp";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { decimals, roundAccurately } from "../../utils/resolvers";
import { groupBy } from "lodash";
import moment from "moment";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("decimals", decimals);
import { debounce } from "lodash";
export default {
  name: "RecordShopSales",
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmationDialog: () => import(`./components/ConfirmationDialog`),
    SnackBar: () => import(`../../components/SnackBar`),
    PageTitle: () => import(`../../components/PageTitle`),
    RecordCashPurchases: () => import(`./components/RecordCashPurchases`),
    RecordCashPayments: () => import(`./components/RecordCashPayments`),
    RecordCreditPurchases: () => import(`./components/RecordCreditPurchases`),
    AddMDsAllocationAmount: () => import(`./dialogs/AddMDsAllocationAmount`)
  },
  data() {
    return {
      today: new Date(),
      date: "",
      menu: {
        add: false,
        edit: false
      },
      endOfDaySales: {
        salesAmount: "",
        cashDeposit: "",
        mdsAllocationAmount: "",
        cashPurchases: [],
        cashPayments: [], // also called credit payment
        creditPurchases: []
      },
      totalAmountTallied: 0,
      temp: {
        cashDeposit: 0,
        mdsAllocation: 0
      },
      hasSalesTallied: false,
      salesPaymentOptions: []
    };
  },
  computed: {
    ...mapGetters({
      loading: "getIsLoading",
      isListLoading: "getIsListLoading",
      currency: "currency/getSystemCurrency",
      subLoading: "getIsSubLoading",
      settings: "settings/getSystemSettings",
      recordedShopSales: "sales/getRecordedShopSales",
      suppliers: "suppliers/getListOfSuppliers",
      allocationAmount: "prices/getAllocationAmount"
    })
  },
  filters: {
    timestamp,
    yesterday(value, format) {
      return moment(value)
        .subtract(1, "day")
        .format(format);
    }
  },
  watch: {
    date(value) {
      this.$store.dispatch("sales/recordedShopSales", { date: value });
    },
    "endOfDaySales.cashPurchases": {
      handler(payload) {
        const amountTallied = this.balancingAmount(
          payload,
          this.endOfDaySales.cashPayments,
          "sales-amount"
        );
        this.totalAmountTallied = amountTallied;
        this.endOfDaySales.cashDeposit = roundAccurately(
          this.temp.cashDeposit - amountTallied,
          2
        );
      },
      deep: true
    },
    "endOfDaySales.cashPayments": {
      handler(payload) {
        const amountTallied = this.balancingAmount(
          payload,
          this.endOfDaySales.cashPurchases,
          "sales-amount"
        );
        this.totalAmountTallied = amountTallied;
        this.endOfDaySales.cashDeposit = roundAccurately(
          this.temp.cashDeposit - amountTallied,
          2
        );
      },
      deep: true
    },
    "endOfDaySales.salesAmount": debounce(function(value) {
      const amount = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      this.endOfDaySales.cashDeposit = roundAccurately(amount, 2);
      this.temp.cashDeposit = roundAccurately(amount, 2);
      this.salesPaymentOptions = [
        ...this.salesPaymentOptions.filter(sale => sale?.id !== "sales-amount"),
        ...[amount > 0 && { id: "sales-amount", value: "Total Sales Amount" }]
      ].filter(sale => sale);
    }, 500),
    allocationAmount: debounce(function(value) {
      const amount = isNaN(roundAccurately(value?.totalAmount, 2))
        ? 0
        : roundAccurately(value?.totalAmount, 2);
      this.endOfDaySales.mdsAllocationAmount = amount;
      this.temp.mdsAllocation = amount;
      this.salesPaymentOptions = [
        ...this.salesPaymentOptions.filter(
          sale => sale?.id !== "mds-allocation"
        ),
        ...[amount > 0 && { id: "mds-allocation", value: "MD's Allocation" }]
      ].filter(sale => sale);
    }, 500)
  },
  mixins: [SnackBarMixins, DialogMixins],
  methods: {
    addCreditPurchase(payload) {
      this.endOfDaySales.creditPurchases.push(payload);
    },
    addCashPurchase(payload) {
      this.endOfDaySales.cashPurchases.push(payload);
    },
    addCashPayment(payload) {
      this.endOfDaySales.cashPayments.push(payload);
    },
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    balancingAmount(
      saleOnChange,
      saleOnStale,
      lookup = "sales-amount",
      key = "amount"
    ) {
      return (
        this.sum(
          saleOnChange.filter(
            sale => sale.isPaymentMadeFromSalesAmount === lookup
          ),
          key
        ) +
        this.sum(
          saleOnStale.filter(
            sale => sale.isPaymentMadeFromSalesAmount === lookup
          ),
          key
        )
      );
    },
    // mds allocation amount
    addAllocationAmount(payload) {
      this.$store.dispatch("prices/addAmountAllocations", payload);
    },
    confirmationAlert() {
      this.open("alert");
    },
    recordDailySales() {
      const { creditPurchases, salesAmount, cashDeposit } = this.endOfDaySales;

      const cashPurchases = this.endOfDaySales.cashPurchases.map(sale => {
        return {
          supplierId: sale.supplierId?.id,
          name: sale.supplierId?.name,
          slug: sale.supplierId?.slug,
          type: sale.type,
          invoiceNumber: sale?.invoiceNumber,
          amount: Number(sale.amount),
          isPaymentMadeFromSalesAmount: sale.isPaymentMadeFromSalesAmount
        };
      });
      const cashPayments = this.endOfDaySales.cashPayments.map(sale => {
        return {
          supplierId: sale.supplierId?.id,
          name: sale.supplierId?.name,
          slug: sale.supplierId?.slug,
          invoiceNumber: sale?.invoiceNumber,
          type: sale.type,
          amount: Number(sale.amount),
          isPaymentMadeFromSalesAmount: sale.isPaymentMadeFromSalesAmount
        };
      });
      const purchasesByCredit = Object.entries(
        groupBy(creditPurchases, "supplierId")
      ).map(([supplierId, sales]) => {
        return {
          supplierId: Number(supplierId),
          amount: this.sum(sales, "amount"),
          invoiceNumber: sales
            .map(({ invoiceNumber }) => invoiceNumber)
            ?.join(","),
          type: "credit-purchase"
        };
      });

      let results = {
        ...this.endOfDaySales,
        salesAmount: roundAccurately(salesAmount, 2),
        cashDeposit: roundAccurately(cashDeposit, 2),
        mdsAllocationAmount: roundAccurately(
          [...cashPurchases, ...cashPayments]
            .filter(
              record =>
                record.isPaymentMadeFromSalesAmount &&
                record.isPaymentMadeFromSalesAmount === "mds-allocation"
            )
            .reduce((acc, sale) => (acc += Number(sale.amount)), 0) ?? 0,
          2
        ),
        salesDate: this.date,
        cashPayments,
        cashPurchases,
        creditPurchases: purchasesByCredit
      };
      this.$store.dispatch("sales/recordDailyShopSales", results);
    }
  },
  created() {
    this.date = "";
    this.$store.dispatch("currency/getSystemCurrency");
    this.$store.dispatch("settings/getSettings");
    this.$store.dispatch("prices/listAllocationAmount");
    this.$store.dispatch("suppliers/list", {
      isPaginated: false,
      page: 1,
      limit: 15
    });
  }
};
</script>
